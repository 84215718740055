import './App.css';
import algosdk from "algosdk";
import { DeflyWalletConnect } from '@blockshake/defly-connect';
import { useEffect } from 'react';



const deflywallet = new DeflyWalletConnect()
const algod_address = "https://mainnet-api.algonode.cloud"
const algod_token = ""
const headers = {"X-Algo-API-Token": algod_token }
const algodClient = new algosdk.Algodv2(algod_token, algod_address, headers);
const address = localStorage.getItem('address');


//MaintNet
const voteAddress1 = '54ZNDTZQIX5N5B6NDS62IOXX4AKLYY3V44EIXW3EFYCYBUS7H3PRAHK2EU'
const voteAddress2 = 'ANYU5ROV2WW5O23UGAQQIKL4AFZIF34BO5V6IIBPSLQ2WU5WLAW7U4MARQ'
const voteAddress3 = 'SEHNTAQLB5Q524Q6DCB5DDB3ETWALG3HMI3LHVGQ4PVTNI2X5JO6ZKK2FU'
const voteAddress4 = '2XWKKD573A26HJD7ZTXWTLNOWIBE33FWLQXU63KH2XVN3TXUO35YVKXDZA'
const voteAddress5 = 'I7262NKWAYGLTUFPYHQEVYOMTUOO22DXCT2JXTIAK2TXKAPSWF4BQBDBFU'
const voteAddress6 = 'TDSPWDWLZFQUFH2ZPO3D47SDT5HAXV24YNGH5TXHWISSFFWIK3E62PDF6M'
const voteAddress7 = 'MFDNEMX3YYZE43GHNYFCTPAKXFJ4P7X7M72STVEYIUN37RKCJC2WIVXW7U'
const voteAddress8 = 'K6D2UX3JUXH5P5BPQNH5DH3UJEXFSUMFX4EJJX23XI3AFGMAWD7JKMLSEE'
const voteAddress9 = 'PMYUXEDSBHVCDVOV43OPHNXDYQIUGF4SE5F3F4LHI4UYAWFH5JM5XXJSBQ'
const voteAddress10 = 'DAF7NMKZIJA662V62H6BXKA2DN2PSY6WZYPTIA7FK6FF6JI3ICDEXHXLKQ'
const voteAddress11 = 'YSDMPFVXWOAU4GSHV2RGOD3IMJMUJUMA4C7UMJA2MIVFQYEC23GCZNF3AY'
const voteAddress12 = '47E66PFKIB6JL56JGJI3WFVAQELRFDTU72RR2FUQDPZ5HZHF542CC3FLDA'
const voteAddress13 = 'E4SLKAGG7JKU2IYAH3XFD4M3A46LEAPDBZMUBFTJCWOGUNMEZOSBDASDTA'
const voteAddress14 = 'NZLWIHYIFFDWIQHV57UWELV4IDVG2HMYTAO4MIBWVKARCSVB5DDAU3AJCU'
const voteAddress15 = '2BMK4QKN4SAG6AGYVNCJYYW6KU54DQCIMAMBEXQ34ECMPJYGWNOMNIFEHE'
const voteAddress16 = 'KPNOTCVCARH7NBEHUH5WISYQM6E4DUATF6KIFIXWI6ROKERN56LQZBA4BM'
const voteAddress17 = 'M6DECPH43CQAZDL3AADT55MQHP3IMONYDZ7HEWZP4NBSODRD52Z5JZYERE'
const voteAddress18 = 'SK5KAWQWWGGETPQ5C2MUULEM26JDTKHTO2UC53UNUTQXFRAGTPYGE4FZPQ'
const voteAddress19 = 'OR5CZ4ZSGDP5AHG2KESU5DKKKBLB7JSMICIZ57R25KQIBDJZT7FO367U24'
const voteAddress20 = 'RQN62RK7DHROCD2S3CFI5VDFYZZRUIXIVBSCQUCJB5PNDPFNLNMUSAGCVA'
const voteAddress21 = '65IND3BZ5FSSHPQDYEAR7UXS7RNCFI3ZXOAQAECLXS45KDCYVHTWJASOJU'
const voteAddress22 = 'IQ2ZAIZ5ICD6RTIBWCEH2ZLHUPMXI6TU3XJWC2MRX2Q7HQFGLVEBXUPZU4'
const voteAddress23 = '4HNGLG3OZZAJULILL2IDXDLKJYSJDDDKQ2BWXQRCJGJ4H2NYU4LSWTDPEM'
const voteAddress24 = 'HYJ4ITHVZMCFZRA53Y267Q2TWI5S3PIEGKFHONH3ECIIK6CV2V3ZRY2X5A'
const voteAddress25 = 'DE5PY5PSCFZALDVBQ34EBRIFMUTIHX4JAK5SSDZRTSFEABU656BYK633SE'
const voteAddress26 = 'SYFT3RGBB6EQNJOOPDN5E3REXZGXFHMQHQ5GSALXGITXRBHK5ZIBOYWE6A'
const voteAddress27 = 'RRRENMKQXY47SILDICCEJRSVAWWQXGRUKYK6VDDAG557Z7RNMMEZTDOCVQ'
const voteAddress28 = 'IDCYBZ7KUBDQPSKYCACZB2T5VJJ3KOLVXOO7L3JFJRQQ4BWI5YXDDJ7GNI'

//Asset ID
//MainNet
const ASSET_ID = 297995609;

// DeFly Connect
async function walletConnect() {
  const newAccounts= await deflywallet.connect()
  localStorage.setItem("address", newAccounts[0]);
  window.location.reload()
  }
const disconnect = () => {
  deflywallet.disconnect()
  localStorage.removeItem("address");
  window.location.reload()
  }

//////////////////////////////////

/////////////////////////
const vote_transaction1 = async () => {
  try{
    const suggestedParams = await algodClient.getTransactionParams().do();
    const txn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: address,
      to: voteAddress1,
      amount: 1000000,
      assetIndex: ASSET_ID,
      suggestedParams,
    });
    const optInTxn = [{txn : txn, signers: [address]}]
    const signedTxn = await deflywallet.signTransaction([optInTxn])
    const success = await algodClient.sendRawTransaction(signedTxn).do();
    return success
  }
  catch(err){
    console.log(err)
    return false
  }
  }
/////////////
const vote_transaction2 = async () => {
  try{
    const suggestedParams = await algodClient.getTransactionParams().do();
    const txn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: address,
      to: voteAddress2,
      amount: 1000000,
      assetIndex: ASSET_ID,
      suggestedParams,
    });
    const optInTxn = [{txn : txn, signers: [address]}]
    const signedTxn = await deflywallet.signTransaction([optInTxn])
    const success = await algodClient.sendRawTransaction(signedTxn).do();
    return success
  }
  catch(err){
    console.log(err)
    return false
  }
  }

/////////////
const vote_transaction3 = async () => {
  try{
    const suggestedParams = await algodClient.getTransactionParams().do();
    const txn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: address,
      to: voteAddress3,
      amount: 1000000,
      assetIndex: ASSET_ID,
      suggestedParams,
    });
    const optInTxn = [{txn : txn, signers: [address]}]
    const signedTxn = await deflywallet.signTransaction([optInTxn])
    const success = await algodClient.sendRawTransaction(signedTxn).do();
    return success
  }
  catch(err){
    console.log(err)
    return false
  }
  }

/////////////
const vote_transaction4 = async () => {
  try{
    const suggestedParams = await algodClient.getTransactionParams().do();
    const txn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: address,
      to: voteAddress4,
      amount: 1000000,
      assetIndex: ASSET_ID,
      suggestedParams,
    });
    const optInTxn = [{txn : txn, signers: [address]}]
    const signedTxn = await deflywallet.signTransaction([optInTxn])
    const success = await algodClient.sendRawTransaction(signedTxn).do();
    return success
  }
  catch(err){
    console.log(err)
    return false
  }
  }

/////////////
const vote_transaction5 = async () => {
  try{
    const suggestedParams = await algodClient.getTransactionParams().do();
    const txn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: address,
      to: voteAddress5,
      amount: 1000000,
      assetIndex: ASSET_ID,
      suggestedParams,
    });
    const optInTxn = [{txn : txn, signers: [address]}]
    const signedTxn = await deflywallet.signTransaction([optInTxn])
    const success = await algodClient.sendRawTransaction(signedTxn).do();
    return success
  }
  catch(err){
    console.log(err)
    return false
  }
  }

/////////////
const vote_transaction6 = async () => {
  try{
    const suggestedParams = await algodClient.getTransactionParams().do();
    const txn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: address,
      to: voteAddress6,
      amount: 1000000,
      assetIndex: ASSET_ID,
      suggestedParams,
    });
    const optInTxn = [{txn : txn, signers: [address]}]
    const signedTxn = await deflywallet.signTransaction([optInTxn])
    const success = await algodClient.sendRawTransaction(signedTxn).do();
    return success
  }
  catch(err){
    console.log(err)
    return false
  }
  }

/////////////
const vote_transaction7 = async () => {
  try{
    const suggestedParams = await algodClient.getTransactionParams().do();
    const txn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: address,
      to: voteAddress7,
      amount: 1000000,
      assetIndex: ASSET_ID,
      suggestedParams,
    });
    const optInTxn = [{txn : txn, signers: [address]}]
    const signedTxn = await deflywallet.signTransaction([optInTxn])
    const success = await algodClient.sendRawTransaction(signedTxn).do();
    return success
  }
  catch(err){
    console.log(err)
    return false
  }
  }

/////////////
const vote_transaction8 = async () => {
  try{
    const suggestedParams = await algodClient.getTransactionParams().do();
    const txn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: address,
      to: voteAddress8,
      amount: 1000000,
      assetIndex: ASSET_ID,
      suggestedParams,
    });
    const optInTxn = [{txn : txn, signers: [address]}]
    const signedTxn = await deflywallet.signTransaction([optInTxn])
    const success = await algodClient.sendRawTransaction(signedTxn).do();
    return success
  }
  catch(err){
    console.log(err)
    return false
  }
  }

/////////////
const vote_transaction9 = async () => {
  try{
    const suggestedParams = await algodClient.getTransactionParams().do();
    const txn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: address,
      to: voteAddress9,
      amount: 1000000,
      assetIndex: ASSET_ID,
      suggestedParams,
    });
    const optInTxn = [{txn : txn, signers: [address]}]
    const signedTxn = await deflywallet.signTransaction([optInTxn])
    const success = await algodClient.sendRawTransaction(signedTxn).do();
    return success
  }
  catch(err){
    console.log(err)
    return false
  }
  }

/////////////
const vote_transaction10 = async () => {
  try{
    const suggestedParams = await algodClient.getTransactionParams().do();
    const txn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: address,
      to: voteAddress10,
      amount: 1000000,
      assetIndex: ASSET_ID,
      suggestedParams,
    });
    const optInTxn = [{txn : txn, signers: [address]}]
    const signedTxn = await deflywallet.signTransaction([optInTxn])
    const success = await algodClient.sendRawTransaction(signedTxn).do();
    return success
  }
  catch(err){
    console.log(err)
    return false
  }
  }

/////////////
const vote_transaction11 = async () => {
  try{
    const suggestedParams = await algodClient.getTransactionParams().do();
    const txn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: address,
      to: voteAddress11,
      amount: 1000000,
      assetIndex: ASSET_ID,
      suggestedParams,
    });
    const optInTxn = [{txn : txn, signers: [address]}]
    const signedTxn = await deflywallet.signTransaction([optInTxn])
    const success = await algodClient.sendRawTransaction(signedTxn).do();
    return success
  }
  catch(err){
    console.log(err)
    return false
  }
  }

/////////////
const vote_transaction12 = async () => {
  try{
    const suggestedParams = await algodClient.getTransactionParams().do();
    const txn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: address,
      to: voteAddress12,
      amount: 1000000,
      assetIndex: ASSET_ID,
      suggestedParams,
    });
    const optInTxn = [{txn : txn, signers: [address]}]
    const signedTxn = await deflywallet.signTransaction([optInTxn])
    const success = await algodClient.sendRawTransaction(signedTxn).do();
    return success
  }
  catch(err){
    console.log(err)
    return false
  }
  }

/////////////
const vote_transaction13 = async () => {
  try{
    const suggestedParams = await algodClient.getTransactionParams().do();
    const txn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: address,
      to: voteAddress13,
      amount: 1000000,
      assetIndex: ASSET_ID,
      suggestedParams,
    });
    const optInTxn = [{txn : txn, signers: [address]}]
    const signedTxn = await deflywallet.signTransaction([optInTxn])
    const success = await algodClient.sendRawTransaction(signedTxn).do();
    return success
  }
  catch(err){
    console.log(err)
    return false
  }
  }

/////////////
const vote_transaction14 = async () => {
  try{
    const suggestedParams = await algodClient.getTransactionParams().do();
    const txn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: address,
      to: voteAddress14,
      amount: 1000000,
      assetIndex: ASSET_ID,
      suggestedParams,
    });
    const optInTxn = [{txn : txn, signers: [address]}]
    const signedTxn = await deflywallet.signTransaction([optInTxn])
    const success = await algodClient.sendRawTransaction(signedTxn).do();
    return success
  }
  catch(err){
    console.log(err)
    return false
  }
  }

/////////////
const vote_transaction15 = async () => {
  try{
    const suggestedParams = await algodClient.getTransactionParams().do();
    const txn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: address,
      to: voteAddress15,
      amount: 1000000,
      assetIndex: ASSET_ID,
      suggestedParams,
    });
    const optInTxn = [{txn : txn, signers: [address]}]
    const signedTxn = await deflywallet.signTransaction([optInTxn])
    const success = await algodClient.sendRawTransaction(signedTxn).do();
    return success
  }
  catch(err){
    console.log(err)
    return false
  }
  }

/////////////
const vote_transaction16 = async () => {
  try{
    const suggestedParams = await algodClient.getTransactionParams().do();
    const txn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: address,
      to: voteAddress16,
      amount: 1000000,
      assetIndex: ASSET_ID,
      suggestedParams,
    });
    const optInTxn = [{txn : txn, signers: [address]}]
    const signedTxn = await deflywallet.signTransaction([optInTxn])
    const success = await algodClient.sendRawTransaction(signedTxn).do();
    return success
  }
  catch(err){
    console.log(err)
    return false
  }
  }


/////////////
const vote_transaction17 = async () => {
  try{
    const suggestedParams = await algodClient.getTransactionParams().do();
    const txn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: address,
      to: voteAddress17,
      amount: 1000000,
      assetIndex: ASSET_ID,
      suggestedParams,
    });
    const optInTxn = [{txn : txn, signers: [address]}]
    const signedTxn = await deflywallet.signTransaction([optInTxn])
    const success = await algodClient.sendRawTransaction(signedTxn).do();
    return success
  }
  catch(err){
    console.log(err)
    return false
  }
  }

/////////////
const vote_transaction18 = async () => {
  try{
    const suggestedParams = await algodClient.getTransactionParams().do();
    const txn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: address,
      to: voteAddress18,
      amount: 1000000,
      assetIndex: ASSET_ID,
      suggestedParams,
    });
    const optInTxn = [{txn : txn, signers: [address]}]
    const signedTxn = await deflywallet.signTransaction([optInTxn])
    const success = await algodClient.sendRawTransaction(signedTxn).do();
    return success
  }
  catch(err){
    console.log(err)
    return false
  }
  }

/////////////
const vote_transaction19 = async () => {
  try{
    const suggestedParams = await algodClient.getTransactionParams().do();
    const txn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: address,
      to: voteAddress19,
      amount: 1000000,
      assetIndex: ASSET_ID,
      suggestedParams,
    });
    const optInTxn = [{txn : txn, signers: [address]}]
    const signedTxn = await deflywallet.signTransaction([optInTxn])
    const success = await algodClient.sendRawTransaction(signedTxn).do();
    return success
  }
  catch(err){
    console.log(err)
    return false
  }
  }

/////////////
const vote_transaction20 = async () => {
  try{
    const suggestedParams = await algodClient.getTransactionParams().do();
    const txn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: address,
      to: voteAddress20,
      amount: 1000000,
      assetIndex: ASSET_ID,
      suggestedParams,
    });
    const optInTxn = [{txn : txn, signers: [address]}]
    const signedTxn = await deflywallet.signTransaction([optInTxn])
    const success = await algodClient.sendRawTransaction(signedTxn).do();
    return success
  }
  catch(err){
    console.log(err)
    return false
  }
  }


/////////////
const vote_transaction21 = async () => {
  try{
    const suggestedParams = await algodClient.getTransactionParams().do();
    const txn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: address,
      to: voteAddress21,
      amount: 1000000,
      assetIndex: ASSET_ID,
      suggestedParams,
    });
    const optInTxn = [{txn : txn, signers: [address]}]
    const signedTxn = await deflywallet.signTransaction([optInTxn])
    const success = await algodClient.sendRawTransaction(signedTxn).do();
    return success
  }
  catch(err){
    console.log(err)
    return false
  }
  }

/////////////
const vote_transaction22 = async () => {
  try{
    const suggestedParams = await algodClient.getTransactionParams().do();
    const txn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: address,
      to: voteAddress22,
      amount: 1000000,
      assetIndex: ASSET_ID,
      suggestedParams,
    });
    const optInTxn = [{txn : txn, signers: [address]}]
    const signedTxn = await deflywallet.signTransaction([optInTxn])
    const success = await algodClient.sendRawTransaction(signedTxn).do();
    return success
  }
  catch(err){
    console.log(err)
    return false
  }
  }


/////////////
const vote_transaction23 = async () => {
  try{
    const suggestedParams = await algodClient.getTransactionParams().do();
    const txn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: address,
      to: voteAddress23,
      amount: 1000000,
      assetIndex: ASSET_ID,
      suggestedParams,
    });
    const optInTxn = [{txn : txn, signers: [address]}]
    const signedTxn = await deflywallet.signTransaction([optInTxn])
    const success = await algodClient.sendRawTransaction(signedTxn).do();
    return success
  }
  catch(err){
    console.log(err)
    return false
  }
  }

/////////////
const vote_transaction24 = async () => {
  try{
    const suggestedParams = await algodClient.getTransactionParams().do();
    const txn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: address,
      to: voteAddress24,
      amount: 1000000,
      assetIndex: ASSET_ID,
      suggestedParams,
    });
    const optInTxn = [{txn : txn, signers: [address]}]
    const signedTxn = await deflywallet.signTransaction([optInTxn])
    const success = await algodClient.sendRawTransaction(signedTxn).do();
    return success
  }
  catch(err){
    console.log(err)
    return false
  }
  }

//////////
const vote_transaction25 = async () => {
  try{
    const suggestedParams = await algodClient.getTransactionParams().do();
    const txn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: address,
      to: voteAddress25,
      amount: 1000000,
      assetIndex: ASSET_ID,
      suggestedParams,
    });
    const optInTxn = [{txn : txn, signers: [address]}]
    const signedTxn = await deflywallet.signTransaction([optInTxn])
    const success = await algodClient.sendRawTransaction(signedTxn).do();
    return success
  }
  catch(err){
    console.log(err)
    return false
  }
  }

//////////
const vote_transaction26 = async () => {
  try{
    const suggestedParams = await algodClient.getTransactionParams().do();
    const txn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: address,
      to: voteAddress26,
      amount: 1000000,
      assetIndex: ASSET_ID,
      suggestedParams,
    });
    const optInTxn = [{txn : txn, signers: [address]}]
    const signedTxn = await deflywallet.signTransaction([optInTxn])
    const success = await algodClient.sendRawTransaction(signedTxn).do();
    return success
  }
  catch(err){
    console.log(err)
    return false
  }
  }

//////////
const vote_transaction27 = async () => {
  try{
    const suggestedParams = await algodClient.getTransactionParams().do();
    const txn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: address,
      to: voteAddress27,
      amount: 1000000,
      assetIndex: ASSET_ID,
      suggestedParams,
    });
    const optInTxn = [{txn : txn, signers: [address]}]
    const signedTxn = await deflywallet.signTransaction([optInTxn])
    const success = await algodClient.sendRawTransaction(signedTxn).do();
    return success
  }
  catch(err){
    console.log(err)
    return false
  }
  }

//////////
const vote_transaction28 = async () => {
  try{
    const suggestedParams = await algodClient.getTransactionParams().do();
    const txn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: address,
      to: voteAddress28,
      amount: 1000000,
      assetIndex: ASSET_ID,
      suggestedParams,
    });
    const optInTxn = [{txn : txn, signers: [address]}]
    const signedTxn = await deflywallet.signTransaction([optInTxn])
    const success = await algodClient.sendRawTransaction(signedTxn).do();
    return success
  }
  catch(err){
    console.log(err)
    return false
  }
  }

//////////////////////////
//////////////////////////
//////////////////////////
//////////////////////////
//////////////////////////
//////////////////////////

(async () => {
  let account_info_1 = (await algodClient.accountInformation(voteAddress1).do());
  let asset_value_1 = account_info_1['assets']
  let asset_amount_1 = asset_value_1[0]["amount"]
  let choice_amount_1 = asset_amount_1 / 100
  document.getElementById('message1').textContent = choice_amount_1 + "Choice"

  let account_info_2 = (await algodClient.accountInformation(voteAddress2).do());
  let asset_value_2 = account_info_2['assets']
  let asset_amount_2 = asset_value_2[0]["amount"]
  let choice_amount_2 = asset_amount_2 / 100
  document.getElementById('message2').textContent = choice_amount_2 + "Choice"

  let account_info_3 = (await algodClient.accountInformation(voteAddress3).do());
  let asset_value_3 = account_info_3['assets']
  let asset_amount_3 = asset_value_3[0]["amount"]
  let choice_amount_3 = asset_amount_3 / 100
  document.getElementById('message3').textContent = choice_amount_3 + "Choice"

  let account_info_4 = (await algodClient.accountInformation(voteAddress4).do());
  let asset_value_4 = account_info_4['assets']
  let asset_amount_4 = asset_value_4[0]["amount"]
  let choice_amount_4 = asset_amount_4 / 100
  document.getElementById('message4').textContent = choice_amount_4 + "Choice"

  let account_info_5 = (await algodClient.accountInformation(voteAddress5).do());
  let asset_value_5 = account_info_5['assets']
  let asset_amount_5 = asset_value_5[0]["amount"]
  let choice_amount_5 = asset_amount_5 / 100
  document.getElementById('message5').textContent = choice_amount_5 + "Choice"

  let account_info_6 = (await algodClient.accountInformation(voteAddress6).do());
  let asset_value_6 = account_info_6['assets']
  let asset_amount_6 = asset_value_6[0]["amount"]
  let choice_amount_6 = asset_amount_6 / 100
  document.getElementById('message6').textContent = choice_amount_6 + "Choice"

  let account_info_7 = (await algodClient.accountInformation(voteAddress7).do());
  let asset_value_7 = account_info_7['assets']
  let asset_amount_7 = asset_value_7[0]["amount"]
  let choice_amount_7 = asset_amount_7 / 100
  document.getElementById('message7').textContent = choice_amount_7 + "Choice"

  let account_info_8 = (await algodClient.accountInformation(voteAddress8).do());
  let asset_value_8 = account_info_8['assets']
  let asset_amount_8 = asset_value_8[0]["amount"]
  let choice_amount_8 = asset_amount_8 / 100
  document.getElementById('message8').textContent = choice_amount_8 + "Choice"

  let account_info_9 = (await algodClient.accountInformation(voteAddress9).do());
  let asset_value_9 = account_info_9['assets']
  let asset_amount_9 = asset_value_9[0]["amount"]
  let choice_amount_9 = asset_amount_9 / 100
  document.getElementById('message9').textContent = choice_amount_9 + "Choice"

  let account_info_10 = (await algodClient.accountInformation(voteAddress10).do());
  let asset_value_10 = account_info_10['assets']
  let asset_amount_10 = asset_value_10[0]["amount"]
  let choice_amount_10 = asset_amount_10 / 100
  document.getElementById('message10').textContent = choice_amount_10 + "Choice"

  let account_info_11 = (await algodClient.accountInformation(voteAddress11).do());
  let asset_value_11 = account_info_11['assets']
  let asset_amount_11 = asset_value_11[0]["amount"]
  let choice_amount_11 = asset_amount_11 / 100
  document.getElementById('message11').textContent = choice_amount_11 + "Choice"

  let account_info_12 = (await algodClient.accountInformation(voteAddress12).do());
  let asset_value_12 = account_info_12['assets']
  let asset_amount_12 = asset_value_12[0]["amount"]
  let choice_amount_12 = asset_amount_12 / 100
  document.getElementById('message10').textContent = choice_amount_12 + "Choice"

  let account_info_13 = (await algodClient.accountInformation(voteAddress13).do());
  let asset_value_13 = account_info_13['assets']
  let asset_amount_13 = asset_value_13[0]["amount"]
  let choice_amount_13 = asset_amount_13 / 100
  document.getElementById('message13').textContent = choice_amount_13 + "Choice"

  let account_info_14 = (await algodClient.accountInformation(voteAddress12).do());
  let asset_value_14 = account_info_14['assets']
  let asset_amount_14 = asset_value_14[0]["amount"]
  let choice_amount_14 = asset_amount_14 / 100
  document.getElementById('message14').textContent = choice_amount_14 + "Choice"

  let account_info_15 = (await algodClient.accountInformation(voteAddress15).do());
  let asset_value_15 = account_info_15['assets']
  let asset_amount_15 = asset_value_15[0]["amount"]
  let choice_amount_15 = asset_amount_15 / 100
  document.getElementById('message15').textContent = choice_amount_15 + "Choice"

  let account_info_16 = (await algodClient.accountInformation(voteAddress16).do());
  let asset_value_16 = account_info_16['assets']
  let asset_amount_16 = asset_value_16[0]["amount"]
  let choice_amount_16 = asset_amount_16 / 100
  document.getElementById('message16').textContent = choice_amount_16 + "Choice"

  let account_info_17 = (await algodClient.accountInformation(voteAddress17).do());
  let asset_value_17 = account_info_17['assets']
  let asset_amount_17 = asset_value_17[0]["amount"]
  let choice_amount_17 = asset_amount_17 / 100
  document.getElementById('message17').textContent = choice_amount_17 + "Choice"

  let account_info_18 = (await algodClient.accountInformation(voteAddress18).do());
  let asset_value_18 = account_info_18['assets']
  let asset_amount_18 = asset_value_18[0]["amount"]
  let choice_amount_18 = asset_amount_18 / 100
  document.getElementById('message18').textContent = choice_amount_18 + "Choice"

  let account_info_19 = (await algodClient.accountInformation(voteAddress19).do());
  let asset_value_19 = account_info_19['assets']
  let asset_amount_19 = asset_value_19[0]["amount"]
  let choice_amount_19 = asset_amount_19 / 100
  document.getElementById('message19').textContent = choice_amount_19 + "Choice"

  let account_info_20 = (await algodClient.accountInformation(voteAddress20).do());
  let asset_value_20 = account_info_20['assets']
  let asset_amount_20 = asset_value_20[0]["amount"]
  let choice_amount_20 = asset_amount_20 / 100
  document.getElementById('message20').textContent = choice_amount_20 + "Choice"

  let account_info_21 = (await algodClient.accountInformation(voteAddress21).do());
  let asset_value_21 = account_info_21['assets']
  let asset_amount_21 = asset_value_21[0]["amount"]
  let choice_amount_21 = asset_amount_21 / 100
  document.getElementById('message21').textContent = choice_amount_21 + "Choice"

  let account_info_22 = (await algodClient.accountInformation(voteAddress22).do());
  let asset_value_22 = account_info_22['assets']
  let asset_amount_22 = asset_value_22[0]["amount"]
  let choice_amount_22 = asset_amount_22 / 100
  document.getElementById('message22').textContent = choice_amount_22 + "Choice"

  let account_info_23 = (await algodClient.accountInformation(voteAddress23).do());
  let asset_value_23 = account_info_23['assets']
  let asset_amount_23 = asset_value_23[0]["amount"]
  let choice_amount_23 = asset_amount_23 / 100
  document.getElementById('message23').textContent = choice_amount_23 + "Choice"

  let account_info_24 = (await algodClient.accountInformation(voteAddress24).do());
  let asset_value_24 = account_info_24['assets']
  let asset_amount_24 = asset_value_24[0]["amount"]
  let choice_amount_24 = asset_amount_24 / 100
  document.getElementById('message24').textContent = choice_amount_24 + "Choice"

  let account_info_25 = (await algodClient.accountInformation(voteAddress25).do());
  let asset_value_25 = account_info_25['assets']
  let asset_amount_25 = asset_value_25[0]["amount"]
  let choice_amount_25 = asset_amount_25 / 100
  document.getElementById('message25').textContent = choice_amount_25 + "Choice"

  let account_info_26 = (await algodClient.accountInformation(voteAddress26).do());
  let asset_value_26 = account_info_26['assets']
  let asset_amount_26 = asset_value_26[0]["amount"]
  let choice_amount_26 = asset_amount_26 / 100
  document.getElementById('message26').textContent = choice_amount_26 + "Choice"

  let account_info_27 = (await algodClient.accountInformation(voteAddress27).do());
  let asset_value_27 = account_info_27['assets']
  let asset_amount_27 = asset_value_27[0]["amount"]
  let choice_amount_27 = asset_amount_27 / 100
  document.getElementById('message27').textContent = choice_amount_27 + "Choice"

  let account_info_28 = (await algodClient.accountInformation(voteAddress28).do());
  let asset_value_28 = account_info_28['assets']
  let asset_amount_28 = asset_value_28[0]["amount"]
  let choice_amount_28 = asset_amount_28 / 100
  document.getElementById('message28').textContent = choice_amount_28 + "Choice"

  })().catch(e => {
    console.log(e);
  });


// React functions must return a React component
function App() {
  useEffect(() => {
    deflywallet.reconnectSession().then((accounts) => {
      if (accounts.length) {
        localStorage.setItem("address", accounts[0]);
      }
      deflywallet.connector?.on("disconnect", () => {
        localStorage.removeItem("address");
      });
    })
    .catch((e) => console.log(e));
  }, [])

  return (
    <div className="App">
      <header className="App-header">
        <p>
        <h1>
          <div id = "displaytext" style={{ color: "blue" }}> Decentralized Decisions </div>
        </h1>
        <h3>
          <div id = "displaytext" style={{ color: "blue" }}> The Choice Coin Prediction Market </div>
        </h3>
        <p>
          <a href="https://medium.com/@ChoiceCoin/choice-coin-prediction-market-rules-ac2d693c9628">
            <button id='button2'>Rules</button>
          </a>
        </p>

        <h3 id = "displaytext" style={{ color: "blue" }}>Wallet Connect</h3>
        <p>
          <button id='button1' onClick={walletConnect}> Connect</button>
          <button id='button2' onClick={disconnect}> Disconnect</button>
          <h3 id = "displaytext" style={{ color: "blue" }}> ______________________ </h3>
        </p>
        </p>

        <h2>
          <div id = "displaytext" style={{ color: "blue" }}> NFL </div>
        </h2>

        <table>

          <tr>
              <th id = "displaytext" style={{ color: "blue" }}>Home</th>
              <th id = "displaytext" style={{ color: "blue" }}>Win</th>
              <th id = "displaytext" style={{ color: "blue" }}>Away</th>
              <th id = "displaytext" style={{ color: "blue" }}>Win</th>
              <th id = "displaytext" style={{ color: "blue" }}>Day</th>
              <th id = "displaytext" style={{ color: "blue" }}>Time</th>
          </tr>

          <tr>
            <td id = "displaytext" style={{ color: "blue" }}>Redskins</td>
            <td id = "displaytext" style={{ color: "blue" }}>8,000</td>
            <td id = "displaytext" style={{ color: "blue" }}>Eagles</td>
            <td id = "displaytext" style={{ color: "blue" }}>10,000</td>
            <td id = "displaytext" style={{ color: "blue" }}>11/14/24</td>
            <td id = "displaytext" style={{ color: "blue" }}>8:15PM</td>
          </tr>

          <tr>
            <td>
              <button id='button3' onClick={vote_transaction3}>Dolphins</button>
            </td>
            <td id = "displaytext" style={{ color: "blue" }}>9,000</td>
            <td>
              <button id='button3' onClick={vote_transaction4}>Raiders</button>
            </td>
            <td id = "displaytext" style={{ color: "blue" }}>9,000</td>
            <td id = "displaytext" style={{ color: "blue" }}>11/17/24</td>
            <td id = "displaytext" style={{ color: "blue" }}>1:00PM</td>
          </tr>

          <tr>
            <td>
              <button id='button3' onClick={vote_transaction5}>Browns</button>
            </td>
            <td id = "displaytext" style={{ color: "blue" }}>10,000</td>
            <td>
              <button id='button3' onClick={vote_transaction6}>Saints</button>
            </td>
            <td id = "displaytext" style={{ color: "blue" }}>8,000</td>
            <td id = "displaytext" style={{ color: "blue" }}>11/17/24</td>
            <td id = "displaytext" style={{ color: "blue" }}>1:00PM</td>
          </tr>

          <tr>
            <td>
              <button id='button3' onClick={vote_transaction7}>Colts</button>
            </td>
            <td id = "displaytext" style={{ color: "blue" }}>8,000</td>
            <td>
              <button id='button3' onClick={vote_transaction8}>Jets</button>
            </td>
            <td id = "displaytext" style={{ color: "blue" }}>10,000</td>
            <td id = "displaytext" style={{ color: "blue" }}>11/17/24</td>
            <td id = "displaytext" style={{ color: "blue" }}>1:00PM</td>
          </tr>

          <tr>
            <td>
              <button id='button3' onClick={vote_transaction9}>Vikings</button>
            </td>
            <td id = "displaytext" style={{ color: "blue" }}>6,000</td>
            <td>
              <button id='button3' onClick={vote_transaction10}>Titans</button>
            </td>
            <td id = "displaytext" style={{ color: "blue" }}>12,000</td>
            <td id = "displaytext" style={{ color: "blue" }}>11/17/24</td>
            <td id = "displaytext" style={{ color: "blue" }}>1:00PM</td>
          </tr>

          <tr>
            <td>
              <button id='button3' onClick={vote_transaction11}>Packers</button>
            </td>
            <td id = "displaytext" style={{ color: "blue" }}>9,000</td>
            <td>
              <button id='button3' onClick={vote_transaction12}>Bears</button>
            </td>
            <td id = "displaytext" style={{ color: "blue" }}>9,000</td>
            <td id = "displaytext" style={{ color: "blue" }}>11/17/24</td>
            <td id = "displaytext" style={{ color: "blue" }}>1:00PM</td>
          </tr>

          <tr>
            <td>
              <button id='button3' onClick={vote_transaction13}>Jaguars</button>
            </td>
            <td id = "displaytext" style={{ color: "blue" }}>13,000</td>
            <td>
              <button id='button3' onClick={vote_transaction14}>Lions</button>
            </td>
            <td id = "displaytext" style={{ color: "blue" }}>5,000</td>
            <td id = "displaytext" style={{ color: "blue" }}>11/17/24</td>
            <td id = "displaytext" style={{ color: "blue" }}>1:00PM</td>
          </tr>

          <tr>
            <td>
              <button id='button3' onClick={vote_transaction15}>Rams</button>
            </td>
            <td id = "displaytext" style={{ color: "blue" }}>7,000</td>
            <td>
              <button id='button3' onClick={vote_transaction16}>Patriots</button>
            </td>
            <td id = "displaytext" style={{ color: "blue" }}>11,000</td>
            <td id = "displaytext" style={{ color: "blue" }}>11/17/24</td>
            <td id = "displaytext" style={{ color: "blue" }}>1:00PM</td>
          </tr>

          <tr>
            <td>
              <button id='button3' onClick={vote_transaction17}>Ravens</button>
            </td>
            <td id = "displaytext" style={{ color: "blue" }}>9,000</td>
            <td>
              <button id='button3' onClick={vote_transaction18}>Steelers</button>
            </td>
            <td id = "displaytext" style={{ color: "blue" }}>9,000</td>
            <td id = "displaytext" style={{ color: "blue" }}>11/17/24</td>
            <td id = "displaytext" style={{ color: "blue" }}>1:00PM</td>
          </tr>

          <tr>
            <td>
              <button id='button3' onClick={vote_transaction19}>49ers</button>
            </td>
            <td id = "displaytext" style={{ color: "blue" }}>8,000</td>
            <td>
              <button id='button3' onClick={vote_transaction20}>Seahawks</button>
            </td>
            <td id = "displaytext" style={{ color: "blue" }}>10,000</td>
            <td id = "displaytext" style={{ color: "blue" }}>11/17/24</td>
            <td id = "displaytext" style={{ color: "blue" }}>4:05PM</td>
          </tr>

          <tr>
            <td>
              <button id='button3' onClick={vote_transaction21}>Falcons</button>
            </td>
            <td id = "displaytext" style={{ color: "blue" }}>9,000</td>
            <td>
              <button id='button3' onClick={vote_transaction22}>Broncos</button>
            </td>
            <td id = "displaytext" style={{ color: "blue" }}>9,000</td>
            <td id = "displaytext" style={{ color: "blue" }}>11/17/24</td>
            <td id = "displaytext" style={{ color: "blue" }}>4:05PM</td>
          </tr>

          <tr>
            <td>
              <button id='button3' onClick={vote_transaction23}>Chiefs</button>
            </td>
            <td id = "displaytext" style={{ color: "blue" }}>8,000</td>
            <td>
              <button id='button3' onClick={vote_transaction24}>Bills</button>
            </td>
            <td id = "displaytext" style={{ color: "blue" }}>10,000</td>
            <td id = "displaytext" style={{ color: "blue" }}>11/17/24</td>
            <td id = "displaytext" style={{ color: "blue" }}>4:25PM</td>
          </tr>

          <tr>
            <td>
              <button id='button3' onClick={vote_transaction25}>Bengals</button>
            </td>
            <td id = "displaytext" style={{ color: "blue" }}>9,000</td>
            <td>
              <button id='button3' onClick={vote_transaction26}>Chargers</button>
            </td>
            <td id = "displaytext" style={{ color: "blue" }}>9,000</td>
            <td id = "displaytext" style={{ color: "blue" }}>11/17/24</td>
            <td id = "displaytext" style={{ color: "blue" }}>8:25PM</td>
          </tr>

          <tr>
            <td>
              <button id='button3' onClick={vote_transaction27}>Texans</button>
            </td>
            <td id = "displaytext" style={{ color: "blue" }}>7,000</td>
            <td>
              <button id='button3' onClick={vote_transaction28}>Cowboys</button>
            </td>
            <td id = "displaytext" style={{ color: "blue" }}>11,000</td>
            <td id = "displaytext" style={{ color: "blue" }}>11/18/24</td>
            <td id = "displaytext" style={{ color: "blue" }}>8:15PM</td>
          </tr>

        </table>

        <break>
        <h3 id = "displaytext" style={{ color: "blue" }}> ______________________ </h3>
        </break>
      </header>
    </div>
  );

}
export default App
